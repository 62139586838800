<template>
  <div class="app-container">
    <ShowTitle text="推播通知" type="title" />

    <el-row>
      <el-col :span="7">
        <div class="left-menu">
          <ProfileMenu />
        </div>
      </el-col>
      <el-col :span="15">
        <!-- <div
          v-loading="submitLoading"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(255, 255, 255, 0.7)"
        > -->
        <el-form label-width="100px" label-position="top">
          <el-form-item>
            <el-checkbox
              :disabled="submitLoading"
              @change="HandleSubmit()"
              v-model="dataObj.signinNotification"
              true-label="1"
              false-label="0"
            >
              登入通知
            </el-checkbox>
            <ShowTitle
              text="當你有新的登入活動時，會寄出一封通知信件到你的電子郵件。"
              type="field-sub-title"
            />
            <el-divider />
          </el-form-item>
        </el-form>
        <!-- </div> -->
      </el-col>
    </el-row>
  </div>
</template>

<script>
import mainCommon from "@/utils/mainCommon";
import configCommon from "@/utils/configCommon";
import { settingList, settingEdit } from "@/api/setting";

export default {
  mixins: [mainCommon],
  data() {
    return {
      submitLoading: false,
      dataObj: {},
    };
  },
  created() {
    this.getDataObj();
  },
  methods: {
    HandleSubmit() {
      this.submitLoading = true;
      settingEdit(this.dataObj)
        .then((res) => {})
        .finally(() => {
          this.submitLoading = false;
        });
    },
    getDataObj() {
      this.submitLoading = true;
      settingList()
        .then((res) => {
          this.dataObj = res.data;
          this.submitLoading = false;
        })
        .finally(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.left-menu {
  top: 10px;
  position: sticky;
}
/deep/ .el-form-item__label {
  padding: 0 0 20px;
  line-height: unset;
}
/deep/ .el-input {
  width: 300px;
}
/deep/ .el-input__inner {
  border-radius: 0;
  padding: 0;
  border: 0;
  width: 300px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
}
.submit-btn {
  padding: 9px 11px;
  user-select: none;
  font-size: 14px;
  appearance: none;
  background-color: #0095f6;
  border-radius: 4px;
  color: #fff;
  border: 0;
  &:disabled {
    opacity: 0.3;
  }
}
</style>