import request from '@/utils/request'

export function settingEdit(data) {
  return request({
    url: '/api/setting/edit',
    method: 'post',
    data: data,
  })
}

export function settingList() {
  return request({
    url: '/api/setting/list',
    method: 'get',
  })
}